import React from "react"
import { LandingLayout } from "@layout"
import { LandingHero } from "@components/hero"
import SEO from "@components/seo"
import {
  MainBlockTitleUnderline,
  LandingJoinColony,
  MainBlockTestimonials,
} from "@components/main-blocks"

const FB1 = () => (
  <LandingLayout
    backgroundImage="animated"
    hero={
      <LandingHero
        h1={
          <>
            Take Surveys
            <br /> For Money
          </>
        }
        h2={<>Get paid to take surveys and share your opinion with brands!</>}
        button="Get Started"
      />
    }
  >
    <SEO
      overrideTitleTemplate={`%s`}
      title="Earn Money with ATM.com - Real Cash Rewards Not Points"
      description="Earn money with the ATM.com® app! Claim your money today, download ATM app! Get paid for answering fun questions."
      image="/img/og/get-extra-cash-with-atm.png"
    />
    <MainBlockTitleUnderline className="white">
      Try the app designed to earn you <span>more money</span>
    </MainBlockTitleUnderline>
    <MainBlockTestimonials h1="App Reviews" />
    <LandingJoinColony
      h1="Get Paid To Take Surveys"
      h2="Make Extra Money And Get Real Cash Rewards Not Points"
      buttonText="Get The App"
    />
  </LandingLayout>
)

export default FB1
